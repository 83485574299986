import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <nav className="navbar">
          <div className="logo">Kactis AI</div>
          <ul className="nav-links">
            {/* <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#features">Features</a>
            </li> */}
            <li>
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </nav>
        <div className="header-content">
          <h1>Welcome to Kactis AI</h1>
          <p>
            Making mobility screening more accessible and using data to make
            healthcare more proactive.
          </p>
        </div>
      </header>
      <main>
        <section id="about" className="description">
          <h2>About us</h2>
          <p>
            Kactis AI is designed to revolutionize mobility screening by
            leveraging advanced AI technology. Our platform ensures that
            mobility assessments are not only more accessible but also more
            accurate and data-driven, enabling proactive healthcare
            interventions.
          </p>
        </section>
        {/* image with yoga picture from public folder */}
        <div className="yoga-image-container">
          <div className="yoga-image-overlay">More information coming soon</div>
          <img src="/yoga.jpg" alt="yoga" className="yoga-image" />
        </div>

        {/* <section id="features" className="description">
          <h2>Features</h2>
          <p>
            Kactis AI is designed to revolutionize mobility screening by
            leveraging advanced AI technology. Our platform ensures that
            mobility assessments are not only more accessible but also more
            accurate and data-driven, enabling proactive healthcare
            interventions.
          </p>
        </section> */}
        <section id="contact" className="contact">
          <h2>Contact Us</h2>
          <p>Phone: (123) 456-7890</p>
          <p>Email: contact@kactisai.com</p>
        </section>
      </main>
      <footer id="contact" className="App-footer">
        <p>© 2024 Kactis AI. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
